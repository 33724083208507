import React from "react"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout";
import { itemListProd } from "../../../utils/sidebar/item-list"
import Container from "../../../components/container";
import { graphql } from "gatsby"
import VideoPlay from "../../../components/common/videoPlay";

class ProductFunctionVisual extends React.Component {

  render() {
    const { location, data } = this.props;
    const _videos = data.markdownRemark.frontmatter.videos
    return (
      <Layout location={location} itemList={itemListProd} contentTitle={data.markdownRemark.frontmatter.title}>
          <SEO title={data.markdownRemark.frontmatter.title} />
          <Container overrideCSS={{maxWidth: '100%', padding: 24, minHeight: 'calc(100vh - 392px)'}}>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
            {_videos.map((item, i) => (
              <div key={i}>
                <VideoPlay videoProps={item} />
              </div>
            ))}
          </Container>
      </Layout>
    )
  }
}

const styles = {

}

export default ProductFunctionVisual

export const pageQuery = graphql`
  query ProductFunctionVisualQuery {
    markdownRemark(
      frontmatter: { type: { eq: "function-visual" } }
    ) {
      html
      frontmatter {
        title
        videos {
          videoTitle
          videoImg {
            publicURL
          }
          videoImg1 {
            publicURL
          }
          videoUrl {
            publicURL
          }
        }
      }
    }
  }
`
